export default {
  emptyEmail: "Please enter Email Address",
  invalidEmail: "Please enter valid Email Address",
  emptyPassword: "Please enter Password",
  invalidPassword: "Please enter minimum 6 letter Password",
  emptyUniversityName: "Please enter University Name",
  invalidUniversityName: "Please enter valid University Name",
  emptyArea: "Please enter your Area",
  invalidArea: "Please enter valid Area",
  emptySector: "Please enter Sector",
  invalidSector: "Please enter valid Sector",
  emptyOTP: "Please enter OTP code",
  invalidOTP: "Please enter valid OTP code",
  emptyNewPassword: "Please enter New Password",
  invalidNewPassword:
    "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (ex. Admin@123)",
  emptyRetypePassword: "Please Retype your Password",
  emptyConfirmPassword: "Please enter Confirm Password",
  invalidConfirmPassword: "Password mismatch",
  emptyTitle: "Please enter Title",
  invalidTitle: "Please enter valid Title",
  emptyPdf: "Please select PDF file",
  emptyPrivacyPolicy: "Please enter Privacy Policy",
  emptyTermsAndConditions: "Please enter Terms and Conditions",
  emptyYearlyCost: "Please enter Yearly Cost",
  invalidYearlyCost: "Please enter valid Yearly Cost",
  emptyVatAmount: "Please enter Vat Percentage",
  invalidVatAmount: "Please enter valid Vat Percentage",
  emptyPostName: "Please enter Post Name",
  invalidPostName: "Please enter valid Post Name",
  emptyInfo: "Please enter Info",
  invalidInfo: "Please enter valid Info",
  emptyImage: "Please upload image",
  invalidImage: "Please upload valid image file",
  emptyVideo: "Please select video",
  selection: "Please select University/Area",
  universitySelection: "Please select University",
  areaSelection: "Please select Area",
  dateSelection: "Please select Date",
  timeSelection: "Please select Time",
  emptyAddress: "Please enter your Address",
  invalidAddress: "Please enter valid Address",
  emptyNoOfTickets: "Please enter Number of tickets available",
  invalidNoOfTickets: "Please enter valid Number of tickets available",
  emptyTicketPrice: "Please enter Price per ticket",
  invalidTicketPrice: "Please enter valid Price per ticket",
  emptySelection: "Please select at least one",
  emptyVoucherCost: "Please enter Voucher Cost",
  invalidVoucherCost: "Please enter valid Voucher Cost",
  emptyVoucherVat: "Please enter Voucher Vat",
  invalidVoucherVat: "Please enter valid Voucher Vat",
  emptyCost: "Please enter Master Meet Up Cost",
  invalidCost: "Please enter valid Master Meet Up Cost",
  videoSize: "Maximum File Size Exceeded (Max file size: 20 MB)",
  pdfSize: "Maximum File Size Exceeded (Max file size: 20 MB)",
};
