import { TableCellDataProps } from "../../../../component/customTable/customTable.interface";

export const tabData: TableCellDataProps[] = [
  {
    id: 1,
    title: "Privacy Policy",
  },
  {
    id: 2,
    title: "Terms & Conditions",
  },
];
