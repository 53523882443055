import { TableCellDataProps } from "../../../../component/customTable/customTable.interface";

export const data: TableCellDataProps[] = [
  {
    id: 1,
    title: "Yes",
  },
  {
    id: 2,
    title: "No",
  },
];
