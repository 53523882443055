export const tableCells = [
  {
    id: 1,
    title: "User name",
  },
  {
    id: 2,
    title: "Date",
  },
  {
    id: 3,
    title: "Type of user",
  },
  {
    id: 4,
    title: "Recommended business",
  },
  {
    id: 5,
    title: "Address",
  },
];
