export enum constant {
  // baseUrl = "https://admin.zeebra-app.com/api/", // Live
  baseUrl = "https://zebra-api.appworkdemo.com/api", // Local
  socket = "https://socket.zeebra-app.com/",
  admin = "admin/",
  common = "Common/",
  // uploads = "https://admin.zeebra-app.com/uploads/", // Live
  uploads = "https://zebra-api.appworkdemo.com/uploads/", // Local
  login = "login",
  universityGetallAdmin = "university-all",
  universityCreate = "university",
  universityUpdate = "university-update",
  universityDelete = "university-delete",
  areaGetallAdmin = "area-down-all",
  areaCreate = "area-down",
  areaUpdate = "area-down-update",
  areaDelete = "area-down-delete",
  sectorGetall = "get-all-sector",
  sectorCreate = "create-sector",
  sectorUpdate = "sector-update",
  sectorDelete = "sector-delete",
  adminGetAllUsers = "zeebra-user-get-all",
  adminGetAllPartners = "zeebra-partner-get-all",
  adminVerifyOtp = "forgot-password",
  adminForgotPassword = "verify-otp",
  adminResetPassword = "reset-password",
  addEditPrivacyPolicy = "add-edit-privacy-and-policy",
  addEditTermsCondition = "add-edit-terms-and-conditions",
  adminResendOtp = "resend-otp",
  getPrivacyPolicy = "getPrivacyPolicy",
  getTermsAndCondition = "getTermsAndCondition",
  changePassword = "change-password",
  adminUpdateMasterCost = "master-cost-update",
  masterCostGetall = "GetAllCost",
  addPostInfo = "add-post-info",
  adminPostDelete = "delete-post-info",
  postInfoUpdate = "update-post-info",
  adminPostInfoGetall = "GetAllPost",
  adminDeleteZeebraUser = "delete-zeebra-user",
  adminDeleteZeebraPartner = "delete-zeebra-partner",
  uploadVideo = "upload-videos",
  updateVideo = "update-video",
  deleteVideo = "delete-video",
  adminGetAllVideos = "get-all-videos",
  addTemplate = "add-offer-template",
  updateTemplate = "update-template",
  deleteTemplate = "delete-template",
  adminGetAllTemplates = "get-all-template",
  adminGetAllOfferRequest = "get-all-offer-request",
  getAllComments = "get-all-comments",
  addCommentForPost = "admin-comment",
  likeOnComment = "admin-like-comment",
  deleteComment = "admin-delete-comment",
  createEvent = "create-zeebra-event",
  deleteEvent = "delete-zeebra-event",
  getEvents = "get-all-zeebra-event",
  getZeebraBusiness = "get-all-zeebra-business",
  updateZeebraBusinessVoucher = "update-voucher-cost-business",
  getAllUserEvents = "get-all-user-event",
  updateEventAmount = "update-event-amount",
  getEventAmount = "get-event-amount",
  getDashboardDetails = "get-dashboard",
  getUserVoucher = "get-user-redeem-voucher",
  deleteUserEvent = "delete-user-event",
  updateZeebraEvent = "update-zeebra-event",
  getTransactionList = "business-transaction",
  getRecommendation = "user-suggestions",
  imageUpload = "image-upload",
  getInvitationList = "invaited-accepted-event",
  ckEditorImage = "admin/add-ck-editer-image",
}
