import Frame from "./images/png/frame.png";
import Earn from "./images/png/earning-card.png";
import Offerimg from "./images/png/offer-img.png";
import Offercreation from "./images/png/offer-creation.png";

const Png = {
  Frame,
  Earn,
  Offerimg,
  Offercreation,
};

export default Png;
